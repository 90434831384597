import React, { useContext, useEffect } from "react";
import "./styles.css";
import GaugeChart from "react-gauge-chart";
import { IonCard, IonText } from "@ionic/react";
import { Text } from "@primer/react";
import BatteryGauge from "react-battery-gauge";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import Tfg_data from "../../context/Tfg_data";
const TemperatureCard = ({ ble, color, percent, gray }: any) => {
  const { temperature, mac, name, humidity, battery, updatedAt } = ble;

  const { tfg_data }: any = useContext(Tfg_data);

  useEffect(() => {
    console.log(mac);
    console.log(temperature);
  }, [mac]);

  const calculateColor = (temperature: number) => {
    const max_temp = tfg_data.max_temperature;

    // Define thresholds for the gradient
    const greenToYellowStart = max_temp * 0.67; // Around 2/3 of max_temp
    const redStartTemp = max_temp - 1; // Red starts near max_temp

    if (temperature <= 0) return "rgb(0, 255, 0)"; // Pure green for very low temperatures
    if (temperature >= redStartTemp) return "rgb(255, 0, 0)"; // Pure red for high temperatures

    // Calculate gradient in green to yellow range
    if (temperature < greenToYellowStart) {
      const percentage = temperature / greenToYellowStart; // Green to yellow progression
      const red = Math.round(percentage * 255);
      const green = 255;
      return `rgb(${red}, ${green}, 0)`; // Transition from green to yellow
    }

    // Calculate gradient in yellow to red range
    const percentage =
      (temperature - greenToYellowStart) / (redStartTemp - greenToYellowStart); // Yellow to red progression
    const red = 255;
    const green = Math.round((1 - percentage) * 255);
    return `rgb(${red}, ${green}, 0)`; // Transition from yellow to red
  };

  return (
    <div className="temperature-card">
      <div
        style={{ textAlign: "center" }}
        className="flex flex-column justify-content-center aling"
      >
        <Text
          as="p"
          sx={{ color: "fg.onEmphasis", bg: "neutral.emphasis", p: 2 }}
        >
          {mac}
        </Text>{" "}
        {name && (
          <Text as="p" sx={{ color: "#333", bg: "#cecece", p: 2 }}>
            {name}
          </Text>
        )}
        <div
          // style={{ width: "100%", background: "red" }}
          className="flex justify-content-center mt-2"
        >
          <BatteryGauge
            customization={{
              batteryCap: { fill: "#22A7F0", strokeColor: "#22A7F0" },
              batteryMeter: { fill: "#22A7F0" },
              batteryBody: { strokeColor: "#22A7F0" },
            }}
            size={50}
            value={battery}
          />
        </div>
        <div
          // className="ave_temp_txt ave temperature"
          style={{
            color: calculateColor(temperature),
            fontSize: "60px",
            fontWeight: "500",
          }}
        >
          {temperature}°C
        </div>
        <div className="flex flex-row	align-items-center justify-content-center p-3">
          <span className="flex flex-row align-items-center justify-content-between">
            <i
              className="pi pi-cloud"
              style={{ fontSize: "1rem", color: "#22A7F0" }}
            ></i>
            <p className="last-updated px-3">Humidity: </p>
          </span>
          <p className="text-sm	font-semibold		" style={{ lineHeight: 0 }}>
            {humidity}
          </p>
        </div>
        <div className="flex flex-row	align-items-center justify-content-between flex-column	 py-3 ">
          <p className="last-updated mb-2">Last Updated</p>
          <p>
            {new Date(updatedAt).toLocaleString("en-US", {
              weekday: "short",
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}
          </p>
        </div>
      </div>
      <div className="gauge_style">
        <GaugeChart
          id="gauge-chart1"
          textColor={"#FFC371"}
          style={{ height: "100px" }}
          hideText={true}
          colors={["#88c7dc", "#FFC371", "#d12121"]}
          arcWidth={0.2}
          percent={percent}
          // percent={
          //   parseFloat(
          //     (temperature / tfg_data.max_temperature).toFixed(2)
          //   ) > 1
          //     ? 1
          //     : parseFloat(
          //         (curr_temps.E1CC3CE4FBA8 / tfg_data.max_temperature).toFixed(
          //           2
          //         )
          //       )
          // }
          nrOfLevels={20}
          animate={false}
          // animDelay={2000}
          // animateDuration={10000}
        />
      </div>
    </div>
  );
};

export default TemperatureCard;
