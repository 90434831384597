import { IonCard, IonText } from "@ionic/react";
import { Card } from "primereact/card";
import React, { useContext } from "react";
import Curr_temps from "../../context/Curr_temps";
import Tfg_data from "../../context/Tfg_data";
import TemperatureCard from "../temp_card";
import { SensorPosition } from "../../pages/Home";

const Temp_floor = ({ position }: any) => {
  const { curr_temps }: any = useContext(Curr_temps);
  const { tfg_data }: any = useContext(Tfg_data);

  const floorNames: any = {
    [SensorPosition.Ground]: "Fine Pick Ground",
    [SensorPosition.First]: "Fine Pick Mezz 1st Floor",
    [SensorPosition.Second]: "Fine Pick Mezz 2nd Floor",
    [SensorPosition.Outbound]: "Outbound Mezz 2nd Floor",
    [SensorPosition.Third]: "Fine Pick 3rd Floor",
  };

  const calculateColor = (temperature: number) => {
    const max_temp = tfg_data.max_temperature;

    // Define thresholds for the gradient
    const greenToYellowStart = max_temp * 0.67; // Around 2/3 of max_temp
    const redStartTemp = max_temp - 1; // Red starts near max_temp

    if (temperature <= 0) return "rgb(0, 255, 0)"; // Pure green for very low temperatures
    if (temperature >= redStartTemp) return "rgb(255, 0, 0)"; // Pure red for high temperatures

    // Calculate gradient in green to yellow range
    if (temperature < greenToYellowStart) {
      const percentage = temperature / greenToYellowStart; // Green to yellow progression
      const red = Math.round(percentage * 255);
      const green = 255;
      return `rgb(${red}, ${green}, 0)`; // Transition from green to yellow
    }

    // Calculate gradient in yellow to red range
    const percentage =
      (temperature - greenToYellowStart) / (redStartTemp - greenToYellowStart); // Yellow to red progression
    const red = 255;
    const green = Math.round((1 - percentage) * 255);
    return `rgb(${red}, ${green}, 0)`; // Transition from yellow to red
  };
  return (
    <div>
      {!!Object.keys(curr_temps)?.length && (
        <>
          <Card
            title={floorNames[position]}
            className="flex p-3 justify-content-center"
          />
          <div>
            <IonCard>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IonText
                  className="ave_temp_text"
                  style={{
                    lineHeight: 1,
                    color: calculateColor(
                      Object.values(curr_temps)
                        .map((ble: any) => ble.temperature)
                        .reduce((acc, val) => acc + val, 0) /
                        Object.values(curr_temps).length
                    ),
                  }}
                >
                  {Object.keys(curr_temps).length
                    ? (
                        Object.values(curr_temps)
                          .filter(
                            (ble: any) =>
                              ble.name && ble.name.includes(position)
                          )
                          .map((ble: any) => ble.temperature)
                          .reduce((acc, val) => acc + val, 0) /
                        Object.values(curr_temps).filter(
                          (ble: any) => ble.name && ble.name.includes(position)
                        ).length
                      ).toFixed(1)
                    : 0}
                  °C
                </IonText>
                <IonText
                  style={{
                    alignSelf: "flex-start  ",
                    color: calculateColor(
                      Object.values(curr_temps)
                        .map((ble: any) => ble.temperature)
                        .reduce((acc, val) => acc + val, 0) /
                        Object.values(curr_temps).length
                    ),
                  }}
                >
                  <p>
                    Floor Average
                    <br /> Temperature
                  </p>
                </IonText>
              </div>
            </IonCard>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              margin: "0 10px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            {Object.values(curr_temps)
              .filter((ble: any) => ble.name && ble.name.includes(position))
              .map((ble: any, i: any) => (
                <TemperatureCard
                  key={i}
                  ble={ble}
                  color={
                    ble.temperature > tfg_data.max_temperature
                      ? "#B7121F"
                      : "#22A7F0"
                  }
                  percent={
                    ble.temperature / tfg_data.max_temperature > 1
                      ? 1
                      : parseFloat(
                          (ble.temperature / tfg_data.max_temperature).toFixed(
                            2
                          )
                        )
                  }
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Temp_floor;
