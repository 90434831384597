import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import {
  json_start_date,
  s3_prifix,
  site_name,
  sub_mail_to,
  url,
} from "../../pages/Home";
import { Calendar } from "primereact/calendar";
import { Button, SelectField } from "@aws-amplify/ui-react";
import { Divider } from "primereact/divider";
import Downloaded_data from "../../context/Donwloaded_data";
import Toast_message from "../../context/Toast_message";
import { downloadExcel } from "react-export-table-to-excel";
import { Label, TextInput } from "@primer/react";
import * as mutations from "../../graphql/mutations";
import Sensors from "../../context/Sensors";
import {
  graphqlOperation,
  GraphQLQuery,
  GraphQLSubscription,
} from "@aws-amplify/api";
import {
  GetSensorsQuery,
  GetSetupQuery,
  OnCreateSensorsSubscription,
  OnUpdateSensorsSubscription,
} from "../../API";
import * as queries from "../../graphql/queries";
import * as subscriptions from "../../graphql/subscriptions";
import Tfg_data from "../../context/Tfg_data";
import { API } from "aws-amplify";
import Curr_temps from "../../context/Curr_temps";
import { useHistory } from "react-router-dom";
import Loading from "../../context/Loading";
import { name_sensors } from "../../pages/Sensors/state";
import Areas from "../../context/Areas";

const Header = () => {
  const history = useHistory();

  const [download_modal, set_download_modal] = useState(false);
  const [open_set_up, set_open_set_up] = useState(false);
  const [start_date, set_start_date]: any = useState("");
  const [add_range, set_add_range]: any = useState(false);
  const [end_date, set_end_date]: any = useState("");
  const [new_tfg_data, set_new_tfg_data] = useState(new Object() as any);
  const [new_ble_name, set_new_ble_name] = useState("");
  const [new_ble_mac, set_new_ble_mac] = useState("");
  const [new_mail, set_new_mail] = useState("");

  const { loading, set_loading } = useContext(Loading);
  const { downloaded_data, set_downloaded_data } = useContext(Downloaded_data);
  const { sensors, set_sensors } = useContext(Sensors);
  const { toast }: any = useContext(Toast_message);
  const { tfg_data, set_tfg_data }: any = useContext(Tfg_data);
  const { curr_temps, set_curr_temps } = useContext(Curr_temps);
  const { areas, set_areas }: any = useContext(Areas);

  useEffect(() => {
    // Sensors
    get_data();

    const sub_to_sensor_create = (
      API.graphql<GraphQLSubscription<OnCreateSensorsSubscription>>(
        graphqlOperation(subscriptions.onCreateSensors)
      ) as any
    ).subscribe({
      next: (payload: any) => {
        const new_data = payload.value.data?.onCreateSensors;
        if (new_data) {
          set_curr_temps((_: any) => {
            return {
              ..._,
              [new_data.mac]: new_data,
            };
          });
        }
      },
      error: (err: any) => console.log(err),
    });

    const sub_to_sensor_update = (
      API.graphql<GraphQLSubscription<OnUpdateSensorsSubscription>>(
        graphqlOperation(subscriptions.onUpdateSensors)
      ) as any
    ).subscribe({
      next: (payload: any) => {
        const new_data: any = payload.value.data?.onUpdateSensors;
        if (new_data) {
          set_curr_temps((_: any) => {
            return {
              ..._,
              [new_data.mac]: new_data,
            };
          });
        }
      },
      error: (err: any) => console.log(err),
    });

    return () => {
      sub_to_sensor_create.unsubscribe();
      sub_to_sensor_update.unsubscribe();
    };
  }, []);

  const get_data = async () => {
    set_loading(true);
    try {
      const response: any = await API.graphql<GraphQLQuery<GetSetupQuery>>(
        graphqlOperation(queries.listSetups)
      );

      if (response?.data?.listSetups?.items?.length) {
        set_tfg_data(response?.data?.listSetups?.items[0]);
      }
      get_sensors();
    } catch (error) {
      console.log(error);
      set_loading(false);
    }
  };

  const get_sensors = async () => {
    try {
      const response: any = await API.graphql<GraphQLQuery<GetSensorsQuery>>(
        graphqlOperation(queries.listSensors)
      );
      if (response?.data?.listSensors) {
        set_sensors(response?.data?.listSensors?.items);
        const months: any = [];
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Month is zero-based
        const monthString = `${year}_${month.toString().padStart(2, "0")}`;
        months.push(s3_prifix + monthString);

        await fetch(url, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify(months),
        })
          .then(async (_: any) => await _.json())
          .then((_: any) => {
            get_areas();
            set_downloaded_data(
              name_sensors(
                Object.values(_)
                  .flat()
                  .filter((sensor: any) => sensor.temperature !== undefined),
                response?.data?.listSensors?.items
              )
            );
          })
          .catch((err) => console.log(err))
          .finally(() => set_loading(false));
      }
    } catch (error) {
      set_loading(false);
      console.log(error);
    }
  };

  const get_areas = async () => {
    try {
      const response: any = await API.graphql<GraphQLQuery<GetSensorsQuery>>(
        graphqlOperation(queries.listAreas)
      );

      if (response?.data?.listSensors) {
        set_areas(response?.data?.listSensors?.items);
        const months: any = [];
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Month is zero-based
        const monthString = `${year}_${month.toString().padStart(2, "0")}`;
        months.push(s3_prifix + monthString);
      }
    } catch (error) {
      set_loading(false);
      console.log(error);
    }
  };
  const donwload_fitlered_data = async () => {
    // ...

    if (!!(start_date && end_date) && start_date >= end_date) {
      toast.current.show({
        severity: "warn",
        summary: "Info",
        detail: "Message Content",
      });
    }

    set_loading(true);
    const months = [];
    let currentDate = new Date(start_date);

    if (start_date && !end_date) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // Month is zero-based
      const monthString = `${year}_${month.toString().padStart(2, "0")}`;
      months.push(s3_prifix + monthString);
    } else
      while (currentDate <= end_date) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Month is zero-based
        const monthString = `${year}_${month.toString().padStart(2, "0")}`;
        months.push(s3_prifix + monthString);

        // Move to the next month     currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          currentDate.getDate()
        );
      }

    await fetch(url, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(months),
    })
      .then(async (_: any) => await _.json())
      .then((_: any) => {
        set_downloaded_data(
          name_sensors(
            Object.values(_)
              .flat()
              .filter((sensor: any) => sensor.temperature !== undefined),
            sensors
          )
        );
        set_start_date("");
        set_end_date("");
        set_add_range("");
      })
      .catch((err) => console.log(err))
      .finally(() => set_loading(false));
  };

  const download_execel = async () => {
    console.log("Sensing");
    set_loading(true);
    try {
      await downloadExcel({
        fileName: "TFG Report Data.xls",
        sheet: "react-export-table-to-excel",
        tablePayload: {
          header: [
            "Timestamp",
            "Mac",
            "Signal Strength",
            "Temperature (°C)",
            "Humidity",
            "Position",
          ],
          body: Object.values(downloaded_data)
            .map((temp: any) => {
              return {
                ...temp,
                position:
                  (sensors.find((sensor: any) => sensor.mac == temp.mac) as any)
                    .name || "",
                timestamp: new Date(temp.timestamp).toLocaleString(),
              };
            })
            .sort(
              (a: any, b: any) =>
                new Date(a.timestamp).valueOf() -
                new Date(b.timestamp).valueOf()
            ),
        },
      });
      // Download complete, stop loading
      set_loading(false);
    } catch (error) {
      // Handle error if download fails
      console.error("Error downloading Excel:", error);
      // Stop loading even if there's an error
      set_loading(false);
    }
  };

  const sumbit_btn = async () => {
    if (!new_tfg_data.max_temperature)
      return toast.current.show({
        summary: "Notification",
        detail: "There is nothing to update",
        icon: "error",
      });

    const input = {
      id: tfg_data.id,
      ...new_tfg_data,
    };
    set_loading(true);
    try {
      await API.graphql({
        query: mutations.updateSetup,
        variables: { input },
      });
      set_loading(false);
    } catch (error) {
      set_loading(false);
      console.log(error);
    }
    set_tfg_data({ ...tfg_data, ...new_tfg_data });
    set_new_tfg_data(new Object() as any);
    // Success

    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: "Data has been updated",
    });
    set_open_set_up(false);
  };

  const submit_sensor_name = async () => {
    if (!new_ble_name || !new_ble_mac)
      return toast.current.show({
        summary: "Notification",
        detail: "Enter Sensor Name",
        severity: "error",
      });
    set_loading(true);
    set_open_set_up(false);
    try {
      await API.graphql({
        query: mutations.updateSensors,
        variables: {
          input: {
            mac: new_ble_mac,
            name: new_ble_name,
          },
        },
      });

      toast.current.show({
        summary: "Notification",
        detail: "Data has been updated",
        severity: "success",
      });
      set_new_ble_mac("");
      set_new_ble_name("");
    } catch (error) {
      console.log(error);
    } finally {
      set_loading(false);
      set_open_set_up(true);
    }
  };

  const sub_email = async () => {
    if (!new_mail) return;
    set_loading(true);

    await fetch(
      "https://uf8vbukiil.execute-api.us-east-1.amazonaws.com/default/sub_to_temp_mails",
      {
        keepalive: true,
        mode: "no-cors",
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          event_type: "subscription",
          email: new_mail,
          sub_to: sub_mail_to,
        }),
      }
    )
      .then(() => {
        set_new_mail("");
        set_loading(false);

        toast.current.show({
          severity: "Success",
          summary: "Email added",
          detail:
            "Check your email for the verification email and confirm the subscription",
        });
      })
      .catch((err) => {
        set_loading(false);
        console.log(err);

        toast.current.show({
          severity: "Error",
          summary: "Error",
          detail: "Error trying to subscribe email",
        });
      });
  };

  return (
    <>
      <IonHeader
        style={{
          height: "5vh",
        }}
      >
        <IonToolbar>
          <IonRow
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alingItem: "center",
            }}
          >
            <Button
              className="flex flex-direction-row cursor-pointer	"
              onClick={() => history.push("/")}
            >
              <img width={50} height={50} src={require("./favicon.png")} />
              <IonTitle className="logo_text">
                TFG {site_name} Temperature Monitoring
              </IonTitle>
            </Button>

            <IonItem className="flex flex-direction-row ">
              <IonItem>
                <IonButton
                  onClick={() => {
                    // set_download_modal(false);

                    history.push("/Sensors");
                  }}
                >
                  <IonLabel>View Trends</IonLabel>
                </IonButton>
              </IonItem>

              <IonItem>
                <IonButtons>
                  <IonButton onClick={() => set_download_modal(true)}>
                    Download
                  </IonButton>
                </IonButtons>
              </IonItem>

              <IonItem>
                <IonButtons>
                  <IonButton onClick={() => set_open_set_up(true)}>
                    Set Up
                  </IonButton>
                </IonButtons>
              </IonItem>
            </IonItem>
          </IonRow>
        </IonToolbar>
      </IonHeader>

      {/* Donwload */}

      <IonModal
        onWillDismiss={() => !loading && set_download_modal(false)}
        isOpen={!!download_modal && !loading}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => set_download_modal(false)}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Download</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <div className="">
            <div className="flex flex-direction-column  my-5">
              <div className="p-float-label">
                <Calendar
                  value={start_date}
                  onChange={(e: any) => set_start_date(e.value)}
                  view="month"
                  dateFormat="mm/yy"
                  minDate={json_start_date}
                  maxDate={new Date()}
                />
                <label htmlFor="birth_date">
                  {!add_range ? "Select " : "Start "} Month
                </label>
              </div>
              {!!start_date && !add_range && (
                <Button
                  className="mx-2"
                  loadingText="Add range"
                  onClick={() => {
                    set_add_range(true);
                  }}
                >
                  Add Range
                </Button>
              )}
              {add_range && <p className="mx-1"> - to - </p>}

              {add_range && (
                <div className="p-float-label">
                  <Calendar
                    value={end_date}
                    onChange={(e: any) => set_end_date(e.value)}
                    view="month"
                    dateFormat="mm/yy"
                    minDate={new Date(start_date.toLocaleDateString())}
                    maxDate={new Date()}
                  />
                  <label htmlFor="birth_date">End Month</label>
                </div>
              )}
            </div>

            <Button
              disabled={start_date == ""}
              loadingText="Download"
              onClick={() => start_date && donwload_fitlered_data()}
            >
              Request data
            </Button>

            <Divider className="my-3" />

            {!!Object.keys(downloaded_data).length && (
              <div className="flex flex-direction-column  my-5">
                <Button
                  loadingText="Download"
                  onClick={() => download_execel()}
                >
                  Download Excel
                </Button>

                <IonButton
                  onClick={() => {
                    set_download_modal(false);
                    history.push("/Sensors");
                  }}
                >
                  <IonLabel>View Trends</IonLabel>
                </IonButton>
              </div>
            )}
          </div>
        </IonContent>
      </IonModal>

      {/* set up */}

      <IonModal
        animated={true}
        backdropDismiss={true}
        className="overflow-auto	"
        isOpen={open_set_up && !loading}
        onWillDismiss={() => !loading && set_open_set_up(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => set_open_set_up(false)}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>Set Up</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div style={{ flex: 1, overflow: "auto" }} className="p-3">
          <div className="flex flex-column align-items-start		">
            <Label>Maximun Temperature</Label>
            <TextInput
              className="my-2"
              onChange={(e) =>
                set_new_tfg_data({
                  ...new_tfg_data,
                  max_temperature: e.target.value,
                })
              }
              value={new_tfg_data.max_temperature}
              placeholder={tfg_data.max_temperature + "°C"}
            />
            <Button loadingText="Updating" onClick={() => sumbit_btn()}>
              Update highest degrees
            </Button>
          </div>
          <IonCard className="p-1">
            <p className="text-color-secondary font-italic">Last reported at</p>
            <p className="font-semibold	"> {tfg_data.temp_last_report}</p>
          </IonCard>

          <div className="flex flex-column align-items-start		">
            <Label>Subscribe email</Label>
            <TextInput
              className="my-2"
              onChange={(e: any) => set_new_mail(e.target.value)}
              value={new_mail || ""}
              placeholder={"Email"}
            />
            <Button loadingText="Updating" onClick={() => sub_email()}>
              Subcribe for Notifications
            </Button>
          </div>

          <Divider className="my-3" />

          <Divider className="my-3" />

          {!!sensors.length && (
            <>
              <SelectField
                label="Update Sensor names"
                descriptiveText="Select Sensor"
                placeholder="Select the sensor to name it"
                onChange={(e) => {
                  set_new_ble_mac(e.target.value);
                  set_new_ble_name("");
                  // set_selected_sensor()
                }}
              >
                {sensors.map((ble: any) => (
                  <option key={ble.mac} value={ble.mac}>
                    {ble.mac + " "} {ble.name}
                  </option>
                ))}
              </SelectField>

              <br />
              {new_ble_mac && (
                <div className="flex flex-column align-items-start		">
                  <Label>Update Sensor names</Label>
                  <SelectField
                    label="Floor name"
                    descriptiveText="Floor name"
                    placeholder="Floor name"
                    onChange={(e) => {
                      set_new_ble_mac(e.target.value);
                      set_new_ble_name("");
                      // set_selected_sensor()
                    }}
                  >
                    {areas.map((ble: any) => (
                      <option key={ble.name} value={ble.name}>
                        {ble.name}
                      </option>
                    ))}
                  </SelectField>
                  <TextInput
                    className="my-2"
                    onChange={(e) => {
                      const text = e.target.value;
                      console.log(text);
                      set_new_ble_name(text);
                    }}
                    value={new_ble_name}
                    placeholder={"Sensor name"}
                  />
                  <Button
                    disabled={!new_ble_name}
                    loadingText="Updating"
                    onClick={() => submit_sensor_name()}
                  >
                    Update Sensor Name
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </IonModal>
    </>
  );
};

export default Header;
