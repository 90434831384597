import React, { useContext, useEffect, useState } from "react";
import { IonCard, IonPage, IonText } from "@ionic/react";
import "./styles.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "react-datepicker/dist/react-datepicker.css";
import { Card } from "primereact/card";
import TemperatureCard from "../../components/temp_card";
import { withRouter } from "react-router-dom";
import Curr_temps from "../../context/Curr_temps";
import Sensors from "../../context/Sensors";
import Tfg_data from "../../context/Tfg_data";
import Loading from "../../context/Loading";
import Temp_floor from "../../components/Temp_floor";
import Areas from "../../context/Areas";

// Change env

// test
// const s3_pdf_file_name = "test_temp_data";
// const sub_mail_to = "test_notifications";
// const site_name = "Test";
// const json_start_date = new Date("2024-03-04");

// Midrand
// export const sub_mail_to = "tfg_temp_increase";
// export const s3_prifix = "midrand_";
// export const site_name = "Midrand";
// export const json_start_date = new Date("2023-06-04");
// const top_sensors = "MEZ";
// const ground_sensors = "GROUND";

// Riverfield
export const sub_mail_to = "north_dc_temp_warnings";
export const s3_prifix = "riverfield_";
export const site_name = "Riverfields";
export const json_start_date = new Date("2023-11-01");
// export const top_sensors = "First floor";
// export const ground_sensors = "Ground";

export enum SensorPosition {
  Ground = "Ground", // 0
  First = "First", // 1
  Second = "Second", // 5
  Outbound = "Outbound",
  Third = "Third", // 5
}

// Cape Towm
// const s3_pdf_file_name = "capetown_report";
// const sub_mail_to = "capetown_temp_increase";
// const site_name = "Cape Town";

export const url =
  "https://z9unyhq478.execute-api.us-east-1.amazonaws.com/default/download_temp_data";

const Home = () => {
  const { sensors } = useContext(Sensors);
  const { curr_temps }: any = useContext(Curr_temps);
  const { tfg_data }: any = useContext(Tfg_data);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  return (
    <IonPage style={{ flex: 1, overflow: "auto", marginTop: "10vh" }}>
      {/* list */}

      {Object.values(SensorPosition).map((po) => (
        <Temp_floor key={po} position={po} />
      ))}

      {!!sensors?.length && (
        <>
          <Card
            title="Awaiting Sensor Data Update"
            className="flex p-3 justify-content-center"
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              margin: "0 10px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            {sensors
              .filter((ble: any) => !curr_temps[ble.mac])
              .map((ble: any, i: any) => (
                <TemperatureCard
                  key={i}
                  ble={ble}
                  color={
                    ble.temperature > tfg_data.max_temperature
                      ? "#B7121F"
                      : "#22A7F0"
                  }
                  percent={
                    ble.temperature / tfg_data.max_temperature > 1
                      ? 1
                      : parseFloat(
                          (ble.temperature / tfg_data.max_temperature).toFixed(
                            2
                          )
                        )
                  }
                />
              ))}
          </div>
        </>
      )}
    </IonPage>
  );
};

export default withRouter(Home);
