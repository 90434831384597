import React, { useState, createContext } from "react";
import PropTypes from "prop-types"; // Import PropTypes

const Areas = createContext({
  areas: [],
  set_areas: (_: any) => {
    //
  },
});

export const Areasprovider = ({ children }: any) => {
  const [areas, set_areas] = useState([]);

  const value = {
    areas,
    set_areas,
  };

  return <Areas.Provider value={value}>{children}</Areas.Provider>;
};

// Add prop validation for the children prop
Areasprovider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Areas;
